<template>
    <div class="m-3">
        Trade moduli...
    </div>
</template>

<script>
export default {
name: "Trade",
        components: {},
        props: {},
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}
</script>

<style scoped>

</style>
